export const TOP = '/'
export const COMPANY = '/company'
export const ACES = '/aces'
export const RECRUITMENTS = '/recruitments'
export const INTERVIEW_HISTORIES = '/interview-histories'
export const USERS = '/users'
export const CREATE_USER = '/users/create'
export const MATCHINGS = '/matchings'
export const MESSAGE_CHAT = '/message-chat'
export const OFFERS = '/offers'
export const SHORTS = '/shorts'

type PageTitleType = {
  [key: string]: string
}

export const PAGE_TITLES: PageTitleType = {
  login: 'ログイン',
  'forgot-password': 'パスワード確認',
  'reset-password': 'パスワードリセット',
  top: 'ダッシュボード',
  company: '会社情報',
  aces: 'エース一覧',
  recruitments: '募集ポジション一覧',
  'interview-histories': '面談履歴',
  create: '作成',
  edit: '編集',
  users: 'メンバー設定',
  matchings: 'マッチング一覧',
  'message-chat': 'メッセージ',
  offers: 'オファー送付',
  shorts: 'ショート',
}
