import { atom } from 'recoil'
import type Company from 'models/Company'

export const logoUrlState = atom<string>({
  key: 'company/logoUrl',
  default: '',
})

export const companyState = atom<Company | null>({
  key: 'company',
  default: null,
})
