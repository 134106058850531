import { Fragment, memo, useEffect } from 'react'
import type { FC } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { crtUserState, mainUserState } from 'globalStates/atoms/authAtom'
import { logoUrlState } from 'globalStates/atoms/companyAtom'
import { listTypeState } from 'globalStates/atoms/offerAtom'
import MaterialIcon from 'components/common/MaterialIcon'
import { NextLink } from 'components/common/NextLink'
import StyledDivider from 'components/common/StyledDivider'
import FirebaseManager from 'utils/FirebaseManager'
import { LINK_URLS } from 'constants/LinkURL'
import { OfferListType, Size } from 'constants/Number'
import * as ROUTE from 'constants/Route'
import SideBarItem from 'models/SideBarItem'
import CompanyLogoPlaceholder from 'images/legacy/company-logo-placeholder.png'

const styles = (theme: any) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      position: 'absolute',
      height: `calc(100% - ${Size.HEADER_HEIGHT}px)`,
      width: Size.SIDE_BAR_WIDTH,
      fontSize: '0.9rem',
      fontWeight: '300',
    },
  },
  drawerPaper: {
    marginTop: Size.HEADER_HEIGHT,
    width: Size.SIDE_BAR_WIDTH,
    height: `calc(100% - ${Size.HEADER_HEIGHT}px)`,
    backgroundColor: '#242424',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
})

const CompanyLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`

const CompanyLogo = styled.img`
  width: ${Size.COMPANY_LOGO_WIDTH}px;
  height: ${Size.COMPANY_LOGO_HEIGHT}px;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 50%;
  object-fit: cover;
`

const listItemProps = css`
  transition: background-color 200ms cubic-bezier(0.4, 0.2, 1) 0ms;

  &&:hover {
    font-weight: 700;
    background: rgb(0.3 0.3 0.3 / 80%);
  }
`

const StyledMainListItem = styled(ListItem)`
  .list-item-icon {
    min-width: 24px;
    margin-right: 0;
  }

  ${listItemProps};
`

const StyledSubListItem = styled(ListItem)`
  ${listItemProps};

  a {
    width: 100%;
    height: 100%;
  }

  /* Override .MuiListItem-root */
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`

const topItem = new SideBarItem({
  id: 'top',
  title: 'ダッシュボード',
  iconName: 'home',
  path: ROUTE.TOP,
})
const aceItem = new SideBarItem({
  id: 'ace',
  title: 'エース一覧',
  iconName: 'person',
  path: ROUTE.ACES,
})
const recruitmentItem = new SideBarItem({
  id: 'recruitment',
  title: '募集ポジション',
  iconName: 'assignment',
  path: ROUTE.RECRUITMENTS,
})
const shortItem = new SideBarItem({
  id: 'shorts',
  title: 'ショート',
  iconName: 'filterNone',
  path: ROUTE.SHORTS,
})
const receivedLike = new SideBarItem({
  id: 'offerLike',
  title: '気になる受け取り',
  iconName: 'star',
  path: ROUTE.OFFERS,
})
const offerItem = new SideBarItem({
  id: 'offer',
  title: 'オファー送付',
  iconName: 'search',
  path: ROUTE.OFFERS,
})
const matchingItem = new SideBarItem({
  id: 'matching',
  title: 'マッチング一覧',
  iconName: 'people',
  path: ROUTE.MATCHINGS,
})
const messageChatItem = new SideBarItem({
  id: 'messageChat',
  title: 'メッセージ',
  iconName: 'chat',
  path: ROUTE.MESSAGE_CHAT,
})
const logoutItem = new SideBarItem({
  id: 'logout',
  title: 'ログアウト',
  iconName: 'arrowBack',
})
const userItem = new SideBarItem({
  id: 'user',
  title: 'メンバー設定',
  iconName: 'user',
  path: ROUTE.USERS,
})
const companyItem = new SideBarItem({
  id: 'company',
  title: '会社情報',
  iconName: 'business',
  path: ROUTE.COMPANY,
})
const informalDecision = new SideBarItem({
  id: 'informalDecision',
  title: '内定報告',
  path: LINK_URLS.informalDecision,
  isExternal: true,
})

const sideBarMainItems = [
  topItem,
  aceItem,
  recruitmentItem,
  shortItem,
  receivedLike,
  offerItem,
  matchingItem,
  messageChatItem,
]

const sideBarSubItems = [companyItem, userItem, informalDecision, logoutItem]

type MenuListItemType = {
  iconName?: string
  title: string
  callback?: () => void
}

const MenuListMainItem: FC<MenuListItemType> = ({
  iconName,
  title,
  callback,
}) => {
  const attr = {
    ...(callback && { onClick: callback }),
  }

  return (
    <StyledMainListItem
      button
      {...attr}
    >
      <ListItemIcon className="list-item-icon">
        <MaterialIcon
          iconName={iconName}
          color="#fff"
        />
      </ListItemIcon>
      <ListItemText
        disableTypography={true}
        primary={title}
        style={{ color: '#fff', padding: '0 10px' }}
      />
    </StyledMainListItem>
  )
}

const MenuListSubItemText: FC<MenuListItemType> = ({ title, callback }) => {
  const attr = {
    ...(callback && { onClick: callback }),
  }

  return (
    <ListItemText
      disableTypography={true}
      primary={title}
      style={{ color: '#fff', fontSize: '0.8rem' }}
      {...attr}
    />
  )
}

const SideBar: FC = (props) => {
  const { classes }: any = props
  const [logoUrl, setLogoUrl] = useRecoilState(logoUrlState)
  const setCrtUser = useSetRecoilState(crtUserState)
  const setMainUser = useSetRecoilState(mainUserState)
  const setOfferListType = useSetRecoilState(listTypeState)

  const logout = () => {
    FirebaseManager.signOut()
      .then((_) => {
        setCrtUser(false)
        setMainUser(false)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.location = `${window.location.origin}/login`
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleClickListItem = (item: SideBarItem) => {
    if (item.id === 'offer') {
      setOfferListType(OfferListType.BASE)
      return
    }
    if (item.id === 'offerLike') {
      setOfferListType(OfferListType.LIKE)
      return
    }
  }

  useEffect(() => {
    const logo = localStorage.getItem('logoURL')
    if (logo) {
      setLogoUrl(logo)
    }
  }, [])

  return (
    <nav className={classes.drawer}>
      <Drawer
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        container={props.container}
        variant="persistent"
        open={true}
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <>
          <CompanyLogoContainer>
            <CompanyLogo
              src={logoUrl || CompanyLogoPlaceholder.src}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onError={(e) => (e.target.src = CompanyLogoPlaceholder.src)}
            />
            <StyledDivider
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              width="40%"
            />
          </CompanyLogoContainer>
          <List>
            <li>
              {sideBarMainItems.map((item) => (
                <NextLink
                  href={item.path}
                  onClick={() => handleClickListItem(item)}
                  key={item.id}
                >
                  <MenuListMainItem
                    iconName={item.iconName}
                    title={item.title}
                  />
                </NextLink>
              ))}
            </li>
          </List>
          <List>
            <li>
              {sideBarSubItems.map((item) => (
                <StyledSubListItem
                  key={item.id}
                  button
                >
                  {item.id === 'logout' ? (
                    <MenuListSubItemText
                      title={item.title}
                      callback={logout}
                    />
                  ) : item.isExternal ? (
                    <a
                      href={item.path}
                      target="_blank"
                    >
                      <MenuListSubItemText title={item.title} />
                    </a>
                  ) : (
                    <NextLink href={item.path}>
                      <MenuListSubItemText title={item.title} />
                    </NextLink>
                  )}
                </StyledSubListItem>
              ))}
            </li>
          </List>
        </>
      </Drawer>
    </nav>
  )
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(memo(SideBar))
