import firebase from 'firebase'
import { isEmpty } from 'lodash'
import AppError, { errorCodes } from 'utils/AppError'
import type Ace from 'models/Ace'
import Company from 'models/Company'

const region = 'asia-northeast1'

const mainConfig = {
  apiKey: process.env.NEXT_PUBLIC_MAIN_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_MAIN_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_MAIN_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_MAIN_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_MAIN_FIREBASE_STORAGE_BACKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MAIN_FIREBASE_MESSAGING_SENDER_ID,
}

const crtConfig = {
  apiKey: process.env.NEXT_PUBLIC_CRT_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_CRT_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_CRT_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_CRT_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_CRT_FIREBASE_STORAGE_BACKET,
  messagingSenderId: process.env.NEXT_PUBLIC_CRT_FIREBASE_MESSAGING_SENDER_ID,
}

type App = firebase.app.App
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export type Firestore = import('firebase/app').default.firestore.Firestore
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export type Functions = import('firebase/app').default.functions.Functions
export type FireStorageRef = firebase.storage.Reference

class FirebaseManager {
  private mainApp: App | null
  private crtApp: App | null
  mainFirestore: Firestore
  crtFirestore: Firestore
  mainFunctions: Functions
  crtFunctions: Functions
  mainAuth: firebase.auth.Auth
  crtAuth: firebase.auth.Auth
  mainStorageRef: firebase.storage.Reference

  constructor() {
    this.mainApp = null
    this.crtApp = null

    firebase.apps.map((app) => {
      if (app.name === 'main') {
        this.mainApp = app
      } else if (app.name === 'crt') {
        this.crtApp = app
      }
    })

    if (!this.mainApp) {
      this.mainApp = firebase.initializeApp(mainConfig, 'main')
      firebase.auth(this.mainApp).languageCode = 'jp'
    }

    if (!this.crtApp) {
      this.crtApp = firebase.initializeApp(crtConfig, 'crt')
      firebase.auth(this.crtApp).languageCode = 'jp'
    }

    this.mainStorageRef = firebase.storage(this.mainApp).ref()

    this.mainFirestore = firebase.firestore(this.mainApp)
    this.crtFirestore = firebase.firestore(this.crtApp)

    this.mainAuth = this.mainApp.auth()
    this.crtAuth = this.crtApp.auth()

    this.crtFunctions = this.crtApp.functions(region)
    this.mainFunctions = this.mainApp.functions(region)
  }

  serverTimeStamp() {
    return firebase.firestore.FieldValue.serverTimestamp()
  }

  currentUser() {
    return this.crtAuth.currentUser
  }

  getCompanyId() {
    return new Promise((resolve, reject) => {
      if (!this.currentUser()) {
        reject(new Error('No current user'))
        return
      }

      const storedCompanyId = localStorage.getItem('cid')
      if (!isEmpty(storedCompanyId)) {
        resolve(storedCompanyId)
        return
      }

      this.currentUser()
        ?.getIdTokenResult()
        .then((result) => {
          const companyId = result.claims.companyId
          if (isEmpty(companyId)) {
            reject(new Error('No company id'))
            return
          }
          localStorage.setItem('cid', companyId)
          resolve(companyId)
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  updateAceEmail(aceId: Ace['id'], email: Ace['email']) {
    return new Promise((resolve, reject) => {
      const updateAceEmail = this.crtFunctions.httpsCallable('updateAceEmail')
      updateAceEmail({ aceId: aceId, email: email })
        .then((result) => {
          resolve(result)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  updateUser(userId: any, name: any, email: any, authority: any) {
    return new Promise((resolve, reject) => {
      if (
        isEmpty(userId) ||
        isEmpty(name) ||
        isEmpty(email) ||
        isEmpty(authority)
      ) {
        reject(new AppError(errorCodes.invalidArgument))
        return
      }

      const updateUser = this.crtFunctions.httpsCallable('updateUser')
      updateUser({
        uid: userId,
        name: name,
        email: email,
        authority: authority,
      })
        .then((result) => {
          resolve(result)
          return
        })
        .catch((error) => {
          if (
            error.code === 'invalid-argument' &&
            error.message === 'auth/invalid-email'
          ) {
            reject(new AppError(errorCodes.invalidEmail))
            return
          }
          reject(error)
          return
        })
    })
  }

  createUser(
    userName: any,
    email: any,
    authority: any,
    isOnMessageNotification: any,
  ) {
    return new Promise<void>((resolve, reject) => {
      this.crtAuth
        .fetchSignInMethodsForEmail(email)
        .then((result) => {
          if (result.length > 0) {
            throw new AppError(errorCodes.alreadyRegisteredEmail)
          }
          const createUser = this.crtFunctions.httpsCallable('createUser')
          return createUser({
            name: userName,
            email: email,
            authority: authority,
            isOnMessageNotification: isOnMessageNotification,
          })
        })
        .then((_) => {
          resolve()
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  deleteUser(userId: string) {
    const deleteUser = this.crtFunctions.httpsCallable('deleteUser')
    return deleteUser({ uid: userId })
  }

  _getCustomToken() {
    return new Promise((resolve, reject) => {
      const getCustomToken = this.crtFunctions.httpsCallable('getCustomToken')
      getCustomToken()
        .then((result) => {
          resolve(result.data.token)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  _signInWithEmailAndPassword(email: string, password: string) {
    return this.crtAuth.signInWithEmailAndPassword(email, password)
  }

  _signInWithCustomToken() {
    return new Promise((resolve, reject) => {
      this._getCustomToken()
        .then((customToken) => {
          return this.mainAuth.signInWithCustomToken(customToken as any)
        })
        .then((result) => {
          resolve(result)
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  signIn(email: string, password: string) {
    return new Promise<void>((resolve, reject) => {
      this._signInWithEmailAndPassword(email, password)
        .then((_) => {
          return this._signInWithCustomToken()
        })
        .then((_) => {
          return this.getCompanyId()
        })
        .then((companyId) => {
          if (isEmpty(companyId)) {
            reject(new Error('No company id'))
            return
          }
          return this.mainFirestore
            .collection(Company.COLLECTION_NAME)
            .doc(companyId as any)
            .get()
        })
        .then((doc) => {
          localStorage.setItem('logoURL', doc?.data()?.logoURL)
          localStorage.setItem('isSignIn', 'true')
          resolve()
          return
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  signOut() {
    return new Promise<void>((resolve, reject) => {
      this.crtAuth
        .signOut()
        .then((_) => {
          this.mainAuth
            .signOut()
            .then((_) => {
              localStorage.removeItem('cid')
              localStorage.removeItem('logoURL')
              localStorage.removeItem('isSignIn')
              resolve()
              return
            })
            .catch((error) => {
              reject(error)
              return
            })
        })
        .catch((error) => {
          reject(error)
          return
        })
    })
  }

  resetAceActionData(aceId: Ace['id']) {
    return this.getCompanyId().then((companyId) => {
      const resetAceActionData =
        this.mainFunctions.httpsCallable('resetAceActionData')
      return resetAceActionData({ aceId: aceId, companyId: companyId })
    })
  }
}

const firebaseManagerInstance = new FirebaseManager()
Object.freeze(firebaseManagerInstance)

export default firebaseManagerInstance
