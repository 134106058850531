import { Record } from 'immutable'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { INDUSTRIES } from 'constants/FieldValue'

const CompanyRecord = Record({
  id: '',
  aceIds: [],
  name: '',
  nameKana: '',
  postalCode: '',
  address: '',
  tel: '',
  locationAddress: '',
  industry: '',
  numOfMembers: '',
  homepageURL: '',
  missionVision: '',
  valueData: [],
  businessDetail: '',
  logoURL: '',
  representativeName: '',
  representativeDepartment: '',
  representativeEmail: '',
  availableAceLimit: 0,
  npBuyerId: '',
  startedAt: null,
  createdAt: null,
  updatedAt: null,
  dashboardUpdatedAt: null,
  jokerKeepCount: 0,
  jokerKeepLimit: 0,
  offerLimit: 0,
  publishedShortCount: 0,
  offerCountPlan: '',
})

class Company extends CompanyRecord {
  static COLLECTION_NAME = 'companies'

  get industryName() {
    if (isEmpty(this.industry)) {
      return ''
    }
    const targetItem = INDUSTRIES.find((item) => item.value === this.industry)
    return (targetItem && targetItem.name) || ''
  }

  get dashboardUpdatedAtString() {
    if (isEmpty(this.dashboardUpdatedAt)) return ' - '
    const dashboradUpdatedAtMoment = moment(
      (this.dashboardUpdatedAt.seconds || 0) * 1000,
    )
    return dashboradUpdatedAtMoment.isValid()
      ? dashboradUpdatedAtMoment.format('YYYY.MM.DD HH:mm')
      : ' - '
  }
}

export default Company
